import './style.css'
import mapboxgl from "mapbox-gl";
import React, {useEffect, useRef} from "react";
import {mapLiveAPIRequest} from "../../pages/map/utils/mapPreviewMode";
import {
    getCategoriesData,
    getFilteredCategoriesData,
    getFilteredLegendsData,
    getLegends, getMapStylesExtraLayers,
    getSelectedMapStyle
} from "../../store/selectors";
import {connect} from "react-redux";
import {getLegendsFromAPI} from "../../store/actions/legends";
import {determineCatIds} from "../../pages/map/utils";
import {getCategoriesFromAPI, getFilteredCategoriesFromAPI} from "../../store/actions/categories";
import ArticleModeHeader from "../articleModeHeader";
import {getSpriteFromAPI} from "../../store/actions/sprite";

mapboxgl.accessToken = process.env.REACT_APP_MAP_TOKEN;

const BasicMap = (props) =>{
    const {content, getSpriteFromAPI} = props
    const map = useRef()
    const mapContainer = useRef()
    const handleGetMapStyle = () => {
        return props.selectedMapStyle[props.selectedMapStyle.active]
    }
    const setupMap = async () =>{
        const styleId = props.selectedMapStyle[props.selectedMapStyle?.active]

        map.current = await new mapboxgl.Map({
            container: mapContainer.current,
            style: `${process.env.REACT_APP_GIS_URL}/BaseMap/${styleId}?articleId=${content?.articleId}`,
            center: [0, 0],
            maxZoom: 8.1,
            zoom: 1,
            transformRequest: (url, resourceType) => {
                let sendUrl = url
                if(sendUrl === `${process.env.REACT_APP_GIS_URL}/BaseSprite/sprite@2x.png`) {
                    return {
                        url: `${sendUrl}?icon=${new Date().getTime()}`,
                        headers: {"access-control-allow-origin" : "*",}
                    }
                }
                if (url.startsWith(process.env.REACT_APP_GIS_URL)) {
                    if (url.startsWith(`${process.env.REACT_APP_GIS_URL}/Tiles`)) {

                        if (!url.includes('year=')){
                            if (!url.includes('?')) {
                                sendUrl += '?'
                            }
                            if(!sendUrl.includes('baseMapId')) {
                                sendUrl += `&baseMapId=${handleGetMapStyle()}&topicid=87`
                            }
                            return {
                                url: `${sendUrl}&year=${content?.year}&isBc=${content?.isBc}`,
                                headers: { 'Cache-Control': 'public' },
                            }
                        }
                    }

                    return {
                        url: sendUrl,
                        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
                    }
                }
            }
            // maxPitch:60,
        })
        const legendsBody = {
            [`articleId`]: content?.articleId,
            baseMapId:styleId || 1,
            includeSubLegends:true
        }
        props.getLegendsFromAPI(legendsBody)
            .then(async (legends) => {
                mapLiveAPIRequest(content?.articleId, 'article').then(async (resData) => {
                    const categories = props.categories
                    const res = props.filteredCategories
                    const subCat = {};
                    const cat = {}
                    categories?.forEach?.((el)=> {
                        cat[el.id] = el
                        el?.subCategories?.forEach?.((item)=> {
                            subCat[item.id] = item
                        })
                    })
                    const features = []
                    if(resData.clusterElements.length > 0){
                        features.push(...resData.clusterElements.reduce((acum,el)=>{
                            // const subId = res.getById(el?.articleIds[0])?.subcategory[0]?.subCategoryId;
                            // const catId = res.getById(el?.articleIds[0])?.categoryId;

                            const article = res.getById(el?.articleIds[0])
                            const mainSub = article?.subcategory?.find(el=>el.isMain)
                            const mainCatId = mainSub?.categoryId || article?.categoryId
                            const mainSubId = mainSub?.subCategoryId

                            acum.push(
                                {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [el.location.longitude, el.location.latitude]
                                    },
                                    properties: {
                                        id:el.id,
                                        relationType:el.lessonRelationType,
                                        articleIds:el.articleIds,
                                        name: el.displayName || 'no name ',
                                        yearStart:+`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
                                        yearEnd:+`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear()}`,
                                        startTime:0,
                                        endTime:0,
                                        visible:'visible',
                                        relationFilter:'visible',
                                        // iconPath:subCat?.iconPath,
                                        iconPath:'https://cdn-icons-png.flaticon.com/512/3201/3201299.png',
                                        // color:subCat?.color || '#fff'
                                        key:  legends?.getKeyById(el?.layerId)?.icon || legends?.getKeyById(el?.layerId)?.key,
                                        subId:mainSubId,
                                        catId:mainCatId,
                                        color: cat[mainCatId]?.color,
                                        catColor:cat[mainCatId]?.color,
                                        // spriteIcon:cat[this.props.filteredCategories.getById(el?.articleIds[0])?.categoryId]?.spriteIcon,
                                        spriteIcon:legends?.getKeyById(el?.layerId)?.icon,
                                        element:true,
                                    }
                                }
                            )
                            return acum
                        },[]))
                    }
                    if(resData.clusterArticles.length > 0){
                        features.push(...resData.clusterArticles.reduce((acum,el,idx)=>{
                            const mainSub = el?.subcategory.find(el=>el.isMain)
                            const mainCatId = mainSub?.categoryId || el?.categoryId
                            const mainSubId = mainSub?.subCategoryId
                            acum.push(
                                {
                                    type: 'Feature',
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [el.location.longitude, el.location.latitude]
                                    },
                                    properties: {
                                        visible:'visible',
                                        relationFilter:'visible',
                                        id:el.id,
                                        relationType:el.lessonRelationType,
                                        articleIds:[el.id],
                                        name: el.content[0]?.shortTitle ? el.content[0]?.shortTitle : (el.content[0]?.mainTitle || 'no name '),
                                        yearStart:+`${el.timeStart?.isBc ? '-' : ''}${el.timeStart?.year || '4714'}`,
                                        yearEnd:+`${el.timeEnd?.isBc ? '-' : ''}${el.timeEnd?.year || new Date().getFullYear() }`,
                                        startTime:0,
                                        endTime:0,
                                        catId:cat[mainCatId]?.id,
                                        catColor:cat[mainCatId]?.color,
                                        subId:subCat[`${mainSubId}`]?.id,
                                        spriteIcon:subCat[`${mainSubId}`]?.spriteIcon,
                                        // iconPath:subCat[`${mainSubId}`]?.iconPath,
                                        // spriteIcon:'c-land_mountain',
                                        // spriteIcon:subCat[`${mainSubId}`]?.spriteIcon,
                                        color:subCat[`${mainSubId}`]?.color || '#fff',
                                        key:subCat[`${mainSubId}`]?.key,
                                        isArticle:true,
                                        active:idx === 0,
                                    }
                                }
                            )
                            return acum
                        },[]))
                    }
                    const sourceOptions = {
                        type: 'geojson',
                        data: {
                            "type": "FeatureCollection",
                            "features": features
                        },
                        cluster: false,

                    }
                    map.current.addSource('Brainograph PIN GIS API', sourceOptions);
                    [props.mapStylesExtraLayers[0],props.mapStylesExtraLayers[1]].forEach(layer=>{
                        map.current.addLayer(layer)
                    })
                    // map.current.addLayer({
                    //     id: 'unclustered-point',
                    //     type: 'circle',
                    //     source: 'Brainograph PIN GIS API',
                    //     filter: ['!', ['has', 'point_count']],
                    //     paint: {
                    //         'circle-color': ['get','catColor'],
                    //         'circle-radius': 15,
                    //         'circle-stroke-width': 1,
                    //         'circle-stroke-color': ['get','catColor'],
                    //     },
                    // });
                    // map.current.addLayer({
                    //     id: 'unclustered-text',
                    //     type: 'symbol',
                    //     source: 'Brainograph PIN GIS API',
                    //     filter: ['!', ['has', 'point_count']],
                    //     layout: {
                    //         'text-field':['get', 'name'],
                    //         'text-font': ['GHEA Grapalat Regular'],
                    //         'text-offset': [0, 0.9],
                    //         'text-anchor': 'top',
                    //         "text-size": 16,
                    //         "text-allow-overlap" : false,
                    //         "text-letter-spacing":0.1,
                    //         // "text-allow-overlap" : true,
                    //         // 'icon-allow-overlap': false,
                    //         'icon-image': ['get', 'spriteIcon'],
                    //         'icon-size': 1,
                    //         'icon-anchor': 'center',
                    //         'icon-offset': [0, 0]
                    //     },
                    //     "paint": {
                    //         'icon-halo-blur':1,
                    //         'icon-halo-width':1.3,
                    //         "text-color":'#000000',
                    //         'text-halo-blur': 1,
                    //         'text-halo-color': 'rgba(255, 255, 255, 0.3)',
                    //         'text-halo-width': 1.3,
                    //     }
                    // });
                    // map.current.addLayer({
                    //     id: 'clusters',
                    //     type: 'circle',
                    //     source: 'Brainograph PIN GIS API',
                    //     filter: ['all', ['has', 'point_count']],
                    //     paint: {
                    //         'circle-color': '#2C476C',
                    //         //     [
                    //         //   'step',
                    //         //   ['get', 'point_count'],
                    //         //   '#2C476C',
                    //         //   10,
                    //         //   '#f1f075',
                    //         //   100,
                    //         //   '#f28cb1'
                    //         // ],
                    //         'circle-radius': [
                    //             'step',
                    //             ['get', 'point_count'],
                    //             15,
                    //             10,
                    //             20,
                    //             40,
                    //             30,
                    //             100,
                    //             35
                    //         ]
                    //     },
                    // });
                    // map.current.addLayer({
                    //     id: 'cluster-count',
                    //     type: 'symbol',
                    //     source: 'Brainograph PIN GIS API',
                    //     filter: ['all',
                    //         ['has', 'point_count'],
                    //     ],
                    //     layout: {
                    //         'text-field': ['get', 'point_count_abbreviated'],
                    //         'text-font': ['GHEA Grapalat Regular'],
                    //         'text-size': 12,
                    //         "text-allow-overlap" : true,
                    //     },
                    //     paint: {
                    //         "text-color": "#ffffff"
                    //     }
                    // });
                })
            })
    }

    useEffect(()=>{
        if (!map.current){
            // getSpriteFromAPI()
            setupMap()
        }
    },[map.current])

    return (
        <section className={'basic-map-box'}>
            <ArticleModeHeader id={content?.articleId}/>
            <div id={"basic-map"} ref={mapContainer}></div>
        </section>
    )
}
const mapStateTopProps = (state) => ({
    categories: getCategoriesData(state),
    filteredCategories: getFilteredCategoriesData(state),
    legends:getLegends(state),
    selectedMapStyle: getSelectedMapStyle(state),
    mapStylesExtraLayers: getMapStylesExtraLayers(state),


})
const mapDispatchToProps = {
    getLegendsFromAPI,
    getCategoriesFromAPI,
    getFilteredCategoriesFromAPI,
    getSpriteFromAPI

}
export default connect(mapStateTopProps, mapDispatchToProps)(BasicMap);
