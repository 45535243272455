import React from 'react';
import TiliIcon from '../components/svg/TiliIcon';
import MaximizeIcon from '../components/svg/MaximizeIcon';
import CloseIcon from '../components/svg/CloseIcon';
import ArrowRightSVG from "../components/svg/ArrowRightSVG";
import ArrowLeftSVG from "../components/svg/ArrowLeftSVG"; // Import your SVG components

import {Splide} from "@splidejs/react-splide";
import MapScreenShotButton from "../../UI/DynamicScreenshotCard/MapScreenShotButton/MapScreenShotButton";
import Play from "../../../assets/imgs/icons/play.svg";
import NavigationSlides from "../components/NavigationSlides";
import {handleYoutubeImagePathGen} from "../../../utils/helpers";
import SlidesCaption from "../components/SlidesCaption";
import Slides from "../components/Slides";


const GalleryView = ({
                           title,
                           tili,
                           toggleMode,
                           toggleFullScreen,
                           isSlideMaximize,
                           toggleGallery,
                           galleryRef,
                           galleryContainer,
                           galleryThumb,
                           galleryCaption,
                           startIdx,
                           gallery,
                           getPageFromUrl,
                           isPresentation,
                           articleId,
                           fromArticle,
                           currentIndex,
                           mapState,
                           galleryItems,
                           updateSlideMaximizeState,
                           convertToNoCookie
                       }) => {
    return (
        <div
            data-tili={!tili}
            className="gallery single active"
            data-full-screen="false"
            ref={galleryRef}
        >
            <div className="gallery-header">
                <div className="gallery-header-title">{title}</div>
                {getPageFromUrl() !== 'true' && (
                    <MapScreenShotButton
                        articleId={articleId}
                        type={isPresentation ? 'presentation-article' : undefined}
                        isReadMoreArticle={fromArticle}
                        galleryIndex={currentIndex}
                        map={mapState}
                        article={true}
                        white={true}
                    />
                )}
                <button
                    className="gallery-header-btn gallery-tili"
                    type="button"
                    onClick={toggleMode}
                >
                    <TiliIcon/>
                </button>
                <button
                    className="gallery-header-btn gallery-maximize"
                    type="button"
                    onClick={() => !isSlideMaximize ? toggleFullScreen(true) : null}
                    style={{opacity: isSlideMaximize ? 0.5 : 1}}
                >
                    <MaximizeIcon/>
                </button>
                <button
                    className="gallery-header-btn gallery-close"
                    type="button"
                    onClick={toggleGallery}
                >
                    <CloseIcon/>
                </button>
            </div>
            <div className="gallery-content splide-content">
                <Splide
                    ref={galleryContainer}
                    options={{
                        type: 'loop',
                        start: startIdx,
                        perPage: 1,
                        perMove: 1,
                        pagination: false,
                        height: window.innerHeight * 0.7,
                        arrows: false,
                    }}
                    renderControls={() => (
                        <div className="splide__arrows" style={{ justifyContent: 'center', display: 'flex' }}>
                            <button className="gallery-btn prev-main splide__arrow--prev" type="button">
                                <ArrowLeftSVG />
                            </button>
                            <button className="gallery-btn next-main splide__arrow--next" type="button">
                                <ArrowRightSVG />
                            </button>
                        </div>
                    )}
                    className="gallery-main"
                >
                    {gallery && <Slides
                                    galleryItems={galleryItems}
                                    convertToNoCookie={convertToNoCookie}
                                />}
                </Splide>
                <Splide
                    ref={galleryThumb}
                    options={{
                        rewind: false,
                        perPage: 5,
                        perMove: 1,
                        gap: '0',
                        pagination: false,
                        fixedWidth: window.innerWidth < 1024 ? 150 : 250,
                        height: window.innerHeight * 0.15,
                        arrows: false,
                        isNavigation: true,
                        start: startIdx,
                    }}
                    className="gallery-items"
                >
                    {gallery && <NavigationSlides
                                    galleryItems={galleryItems}
                                    updateSlideMaximizeState={updateSlideMaximizeState}
                                    handleYoutubeImagePathGen={handleYoutubeImagePathGen}
                                    playIcon={Play}
                                />}
                </Splide>
                <Splide
                    ref={galleryCaption}
                    options={{
                        perPage: 1,
                        pagination: false,
                        height: window.innerHeight * 0.85,
                        width: '100%',
                        arrows: false,
                        drag: false,
                        start: startIdx,
                    }}
                    className="gallery-text-content"
                >
                    {gallery && <SlidesCaption galleryItems={galleryItems} />}
                </Splide>
            </div>
        </div>
    );
};

export default GalleryView;
